// Homepage Motustrength.it

import React from 'react';

const HomePage = () => {
   return (
      // Struttura HTML Della pagina
      <div>
         <h1>Welcome to the Homexxx Page</h1>
      </div>
   );
};

export default HomePage;