// Import delle dipendenze
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Import delle pagine
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import SignupPage from './pages/SignupPage';

// Import dei components
import Navbar from './components/Sidenav';

// Import Stili CSS
import './App.css';

function App() {
  return (
   // Struttura HTML Base dell'app
   <Router>
      <Navbar />
      <Routes>
         <Route path="/" element={<HomePage />} />
         <Route path="/about" element={<AboutPage />} />
         <Route path="/signup" element={<SignupPage />} />
      </Routes>

   </Router>
  );
}

export default App;
