// SignupPage.js - Pagina di registrazione
import React, { useState } from 'react';
import axios from 'axios';

const Register = () => {
   const [username, setUsername] = useState('');
   const [email, setEmail] = useState('');
   const [password, setPassword] = useState('');
   const [message, setMessage] = useState('');

   // Usa la variabile d'ambiente per l'URL dell'API
   const API_URL = process.env.REACT_APP_API_URL || 'https://www.motustrength.it';

   const handleRegister = (e) => {
      e.preventDefault();
      console.log('API_URL:', API_URL);
      axios.post(`${API_URL}/signup`, {
         username,
         email,
         password
      })
      .then((response) => {
         setMessage(response.data.message);
      })
      .catch((error) => {
         console.error('Errore durante la registrazione:', error.response ? error.response.data : error.message);
         setMessage('Errore durante la registrazione');
      });
   };

   return (
   <div>
      <h2>Registrazione</h2>
      <form onSubmit={handleRegister}>
         <div>
            <label>Username:</label>
            <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            />
         </div>
         <div>
            <label>Email:</label>
            <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            />
         </div>
         <div>
            <label>Password:</label>
            <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            />
         </div>
         <button type="submit">Registrati</button>
      </form>
      {message && <p>{message}</p>}
   </div>
   );
};

export default Register;