// AboutPage Motustrength.it

import React from 'react';

const AboutPage = () => {
   return (
      // Struttura HTML Della pagina
      <div>
         <h1>Welcome to the About Page</h1>
      </div>
   );
};

export default AboutPage;