// Area di navigazione principale del sito

import React from 'react';
import { Link } from 'react-router-dom';

const Sidenav = () => {
   return (
   <nav>
      <ul>
         <li>
            <Link to="/">Home</Link>
         </li>
         <li>
            <Link to="/about">About</Link>
         </li>
         <li>
            <Link to="/signup">Signup</Link>
         </li>
      </ul>
   </nav>
  );
};

export default Sidenav;